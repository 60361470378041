<template>
  <div data-app>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" md="6" style="color: #e33354" class="ml-4">
            <h3>
              {{ cardTitle }}
              <TooltipModal
                :title="$t('ALERT.info_title')"
                :text="$helpers.getActiveSiteI18Property( 'INFO_BOX.PARTNER_CONNECTIONS')"
              ></TooltipModal>
            </h3>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="$t('FORMS.search')"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row v-if="permissionCan('upload')">
          <v-col cols="12" sm="8" md="8">
            <input
              type="file"
              ref="fileInput"
              style="display: none"
              @change="handleFileUpload"
            />
            <v-btn color="primary" @click="openFileInput">Fájl feltölés</v-btn>
          </v-col>
          <v-spacer></v-spacer>

          <v-col cols="12" sm="2" md="2">
            <v-select
              v-model="locale"
              :items="languages"
              :disabled="languages.length == 1"
              @change="fetchModel()"
            >
              <template slot="selection" slot-scope="slotProps">
                <span class="symbol symbol-20 mr-3">
                  <img :src="locale.flag" alt="" />
                </span>
                {{ slotProps.item.name }}
              </template>
              <template v-slot:item="slotProps">
                <span class="symbol symbol-20 mr-3">
                  <img :src="slotProps.item.flag" alt="" />
                </span>
                <span class="navi-text">{{ slotProps.item.name }}</span>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="filteredCollection"
          :search="search"
          :loading="loadingTable"
          :footer-props="{
            'items-per-page-options': [100],
          }"
        >
          <template #header="{ props: { headers } }">
            <thead class="v-data-table-header">
              <tr>
                <th>
                  <v-select
                    v-model="intezmenyNeveFilter"
                    :items="['Összes', 'Üres', ...intezmenyNeveArray]"
                  ></v-select>
                </th>
                <th>
                  <v-select
                    v-model="intezmenyKarFilter"
                    :items="['Összes', 'Üres', ...intezmenyKarArray]"
                  ></v-select>
                </th>
                <th></th>
                <th></th>
                <th>
                  <v-select
                    v-model="partnerIntezmenyOrszagaFilter"
                    :items="['Összes', 'Üres', ...partnerIntezmenyOrszagaArray]"
                  ></v-select>
                </th>
                <th>
                  <v-select
                    v-model="partnerIntezmenyNeveFilter"
                    :items="['Összes', 'Üres', ...partnerIntezmenyNeveArray]"
                  ></v-select>
                </th>
                <th></th>
                <th></th>
                <th></th>
                <th>
                  <v-select
                    v-model="egyuttmukodesiTeruletFilter"
                    :items="['Összes', 'Üres', ...egyuttmukodesiTeruletArray]"
                  ></v-select>
                </th>
                <th></th>
                <th></th>
                <th>
                  <v-select
                    v-model="egyuttmukodesTipusaFilter"
                    :items="['Összes', 'Üres', ...egyuttmukodesTipusaArray]"
                  ></v-select>
                </th>
                <th></th>
                <th></th>
                <th></th>
                <th>
                  <v-select
                    v-model="kapcsolatMegoszthatosagaFilter"
                    :items="[
                      'Összes',
                      'Üres',
                      ...kapcsolatMegoszthatosagaArray,
                    ]"
                  ></v-select>
                </th>
              </tr>
            </thead>
          </template>
        </v-data-table>
      </v-card-text>
      <SnackBarInfoComponent :snackbarInfo="snackbarInfo">
      </SnackBarInfoComponent>
      <DeleteModalDialog
        :dialogDelete="dialogDelete"
        @closeDelete="handleCloseDelete"
        @deleteItemConfirm="handleDeleteItemConfirm"
      >
      </DeleteModalDialog>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";
import i18nService from "@/core/services/i18n.service.js";
import DeleteModalDialog from "@/view/components/DeleteModalDialog";
import ApiService from "@/core/services/api.service";
import SnackBarInfoComponent from "@/view/components/SnackBarInfoComponent";

export const PERMISSION_TO = "partner_connections.partner_connection.";

export default {
  name: "partnerConnections",
  components: { DeleteModalDialog, SnackBarInfoComponent },
  mixins: [listModelsMixins],
  data() {
    return {
      cardTitle: this.$t("MENU.PARTNER_CONNECTIONS"),
      search: "",
      routePath: "/settings/partnerConnections/partnerConnections",
      languages: i18nService.languages,
      locale: {
        lang: "hu",
        name: "Magyar",
        flag: "/media/svg/flags/115-hungary.svg",
      },

      permissionTo: PERMISSION_TO,
      permissions: JSON.parse(localStorage.permissions),
      headers: [
        {
          text: "Intézmény neve",
          value: "tagintezmeny_neve",
          // filter: (value) => {
          //   if (this.intezmenyNeveFilter == "Összes") return true;
          //   if (this.intezmenyNeveFilter == "Üres") return value == null;
          //   return value == this.intezmenyNeveFilter;
          // },
        },
        {
          text: "Kar",
          value: "tagintezmeny_kara",
          // filter: (value) => {
          //   if (this.intezmenyKarFilter == "Összes") return true;
          //   if (this.intezmenyKarFilter == "Üres") return value == null;
          //   return value == this.intezmenyKarFilter;
          // },
        },
        {
          text: "Tanszék",
          value: "tagintezmeny_tanszeke",
        },
        {
          text: "Elérhetőség",
          value: "tagintezmeny_elerhetoseg",
        },
        {
          text: "Partner országa",
          value: "partnerintezmeny_orszaga",

          // filter: (value) => {
          //   if (this.partnerIntezmenyOrszagaFilter == "Összes") return true;
          //   if (this.partnerIntezmenyOrszagaFilter == "Üres")
          //     return value == null;
          //   return value == this.partnerIntezmenyOrszagaFilter;
          // },
        },
        {
          text: "Partner neve",
          value: "partnerintezmeny_neve",
          // filter: (value) => {
          //   if (this.partnerIntezmenyNeveFilter == "Összes") return true;
          //   if (this.partnerIntezmenyNeveFilter == "Üres") return value == null;
          //   return value == this.partnerIntezmenyNeveFilter;
          // },
        },
        { text: "Rövidítés", value: "partnerintezmeny_nevenek_roviditese" },
        { text: "Kar", value: "partnerintezmeny_kara" },
        { text: "Tanszék", value: "partnerintezmeny_tanszeke" },
        {
          text: "Együttműködési / kutatási terület",
          value: "egyuttmukodesi_terulet",
          // filter: (value) => {
          //   if (this.egyuttmukodesiTeruletFilter == "Összes") return true;
          //   if (this.egyuttmukodesiTeruletFilter == "Üres")
          //     return value == null;
          //   return value == this.egyuttmukodesiTeruletFilter;
          // },
        },
        {
          text: "Együttműködés kezdete",
          value: "egyuttmukodes_kezdet",
        },
        { text: "Együttműködés vége", value: "egyuttmukodes_vege" },
        {
          text: "Együttműködés típusa",
          value: "egyuttmukodes_tipusa",
          // filter: (value) => {
          //   if (this.egyuttmukodesTipusaFilter == "Összes") return true;
          //   if (this.egyuttmukodesTipusaFilter == "Üres") return value == null;
          //   return value == this.egyuttmukodesTipusaFilter;
          // },
        },
        { text: "Kapcsolat erőssége", value: "kapcsolat_erossege" },
        { text: "Kiemelkedő eredmények", value: "kiemelkedo_eredmenyek" },
        { text: "Az együttműködés rövid leírása", value: "tovabbi_informacio" },
        {
          text: "Kapcsolat megoszthatósága",
          value: "kapcsolat_megoszthatosaga",
        },
      ],

      modalData: {
        dialog: false,
        editedId: null,
      },

      dialogDelete: false,
      partnerConnectionCollection: [],

      selectedFile: null,
      loadingTable: false,

      snackbarInfo: {
        active: false,
        text: this.$t("SNACK_BAR.saved"),
        timeout: 2000,
        icon: "check_circle",
        color: "green",
      },

      intezmenyNeveFilter: "Összes",
      intezmenyKarFilter: "Összes",
      partnerIntezmenyOrszagaFilter: "Összes",
      partnerIntezmenyNeveFilter: "Összes",
      egyuttmukodesiTeruletFilter: "Összes",
      egyuttmukodesTipusaFilter: "Összes",
      kapcsolatMegoszthatosagaFilter: "Összes",
      loader: false,
    };
  },

  computed: {
    ...mapGetters([
      // "getFilingBookByID", "statuses", "userCollection"
    ]),

    endPoint() {
      return `partnerConnections/admin/1/${this.locale.lang}/partnerConnection/upload`;
    },

    intezmenyNeveArray() {
      let array = this.filteredCollection.filter(
        (item) => item.tagintezmeny_neve
      );
      return [...new Set(array.map((item) => item.tagintezmeny_neve))];
    },
    intezmenyKarArray() {
      let array = this.filteredCollection.filter(
        (item) => item.tagintezmeny_kara
      );
      return [...new Set(array.map((item) => item.tagintezmeny_kara))];
    },
    partnerIntezmenyOrszagaArray() {
      let array = this.filteredCollection.filter(
        (item) => item.partnerintezmeny_orszaga
      );
      return [...new Set(array.map((item) => item.partnerintezmeny_orszaga))];
    },

    partnerIntezmenyNeveArray() {
      let array = this.filteredCollection.filter(
        (item) => item.partnerintezmeny_neve
      );
      return [...new Set(array.map((item) => item.partnerintezmeny_neve))];
    },
    egyuttmukodesiTeruletArray() {
      let array = this.filteredCollection.filter(
        (item) => item.egyuttmukodesi_terulet
      );
      return [...new Set(array.map((item) => item.egyuttmukodesi_terulet))];
    },
    egyuttmukodesTipusaArray() {
      let array = this.filteredCollection.filter(
        (item) => item.egyuttmukodes_tipusa
      );
      return [...new Set(array.map((item) => item.egyuttmukodes_tipusa))];
    },
    kapcsolatMegoszthatosagaArray() {
      let array = this.filteredCollection.filter(
        (item) => item.kapcsolat_megoszthatosaga
      );
      return [...new Set(array.map((item) => item.kapcsolat_megoszthatosaga))];
    },

    filteredCollection() {
      return this.partnerConnectionCollection.filter((item) => {
        let filtered = true;

        if (this.intezmenyNeveFilter != "Összes") {
          if (this.intezmenyNeveFilter == "Üres") {
            if (item.tagintezmeny_neve != null) {
              filtered = false;
            }
          } else if (item.tagintezmeny_neve != this.intezmenyNeveFilter) {
            return false;
          }
        }

        if (this.intezmenyKarFilter != "Összes") {
          if (this.intezmenyKarFilter == "Üres") {
            if (item.tagintezmeny_kara != null) {
              filtered = false;
            }
          } else if (item.tagintezmeny_kara != this.intezmenyKarFilter) {
            return false;
          }
        }

        if (this.partnerIntezmenyOrszagaFilter != "Összes") {
          if (this.partnerIntezmenyOrszagaFilter == "Üres") {
            if (item.partnerintezmeny_orszaga != null) {
              filtered = false;
            }
          } else if (
            item.partnerintezmeny_orszaga != this.partnerIntezmenyOrszagaFilter
          ) {
            return false;
          }
        }

        if (this.partnerIntezmenyNeveFilter != "Összes") {
          if (this.partnerIntezmenyNeveFilter == "Üres") {
            if (item.partnerintezmeny_neve != null) {
              filtered = false;
            }
          } else if (
            item.partnerintezmeny_neve != this.partnerIntezmenyNeveFilter
          ) {
            return false;
          }
        }

        if (this.egyuttmukodesiTeruletFilter != "Összes") {
          if (this.egyuttmukodesiTeruletFilter == "Üres") {
            if (item.egyuttmukodesi_terulet != null) {
              filtered = false;
            }
          } else if (
            item.egyuttmukodesi_terulet != this.egyuttmukodesiTeruletFilter
          ) {
            return false;
          }
        }

        if (this.egyuttmukodesTipusaFilter != "Összes") {
          if (this.egyuttmukodesTipusaFilter == "Üres") {
            if (item.egyuttmukodes_tipusa != null) {
              filtered = false;
            }
          } else if (
            item.egyuttmukodes_tipusa != this.egyuttmukodesTipusaFilter
          ) {
            return false;
          }
        }

        if (this.kapcsolatMegoszthatosagaFilter != "Összes") {
          if (this.kapcsolatMegoszthatosagaFilter == "Üres") {
            if (item.kapcsolat_megoszthatosaga != null) {
              filtered = false;
            }
          } else if (
            item.kapcsolat_megoszthatosaga !=
            this.kapcsolatMegoszthatosagaFilter
          ) {
            return false;
          }
        }

        return filtered;
      });
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions([
      // "fetchFiling", "fetchUser"
    ]),

    openFileInput() {
      this.$refs.fileInput.click();
    },

    handleFileUpload(event) {
      const file = event.target.files[0];
      this.selectedFile = file;
      this.uploadFile();
    },

    uploadFile() {
      if (this.selectedFile) {
        this.loader = true;
        const formData = new FormData();
        formData.append("file", this.selectedFile);

        ApiService.post(this.endPoint, formData)
          .then(() => {
            this.snackbarInfo.text = "Sikeres feltölés!";
            this.snackbarInfo.icon = "check_circle";
            this.snackbarInfo.color = "green";
            this.showSnackBarInfo();
            this.selectedFile = null;
            this.fetchModel();
          })
          .catch((error) => {
            this.snackbarInfo.text = "Sikertelen feltölés!";
            this.snackbarInfo.icon = "mdi-alert-circle";
            this.snackbarInfo.color = "red";
            this.showSnackBarInfo();

            console.log("Fájl hiba:", this.selectedFile);
            if (error.response) {
              let errors = error.response.data;
              if (errors) {
                for (let field in errors.errors) {
                  this.setError(field, errors.errors[field][0]);
                }
                console.log(this.messages);
              }
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("Error", error.message);
            }
            console.log("Error!: ", error);
          })
          .finally(() => {
            this.loader = false;
          });
      }
    },

    fetchModel() {
      this.loadingTable = true;

      ApiService.get(
        `partnerConnections/admin/1/${this.locale.lang}/partnerConnection`
      )
        .then(({ data }) => {
          if (!data) {
            this.partnerConnectionCollection = [];
          } else {
            this.partnerConnectionCollection = data.map((row) => {
              let partnerConnection = {};
              partnerConnection.tagintezmeny_neve = row[0];
              partnerConnection.tagintezmeny_kara = row[1];
              partnerConnection.tagintezmeny_tanszeke = row[2];
              partnerConnection.tagintezmeny_elerhetoseg = row[3];
              partnerConnection.partnerintezmeny_orszaga = row[4];
              partnerConnection.partnerintezmeny_neve = row[5];
              partnerConnection.partnerintezmeny_nevenek_roviditese = row[6];
              partnerConnection.partnerintezmeny_kara = row[7];
              partnerConnection.partnerintezmeny_tanszeke = row[8];
              partnerConnection.egyuttmukodesi_terulet = row[9];
              partnerConnection.egyuttmukodes_kezdet = row[10];
              partnerConnection.egyuttmukodes_vege = row[11];
              partnerConnection.egyuttmukodes_tipusa = row[12];
              partnerConnection.kapcsolat_erossege = row[13];
              partnerConnection.kiemelkedo_eredmenyek = row[14];
              partnerConnection.tovabbi_informacio = row[15];
              partnerConnection.kapcsolat_megoszthatosaga = row[16];

              return partnerConnection;
            });
          }

          this.filteredCollection = this.partnerConnectionCollection;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },

    showSnackBarInfo() {
      let snackbarInfo = this.snackbarInfo;

      snackbarInfo.active = true;

      this.snackbarInfo = snackbarInfo;
    },
  },

  mounted() {
    this.routePath = this.$router.currentRoute.path;

    this.loadingTable = true;
    this.fetchModel();
  },
};
</script>
